import React, { useState } from 'react'

import FalsePlayButton from '../../components/false-play-button/falsePlayButton'
import { getCardType } from '../utils/helpers'

import DateComponent from '../date/Date'
import Headline from '../headline/Headline'
import ImageLink from '../image-link/ImageLink'
import Tag from '../tag/Tag'
import Teaser from '../teaser/Teaser'
import { CARD_TYPES } from '../../assets/constants'
import { getRandomPlaceholderImage } from '../utils/images'
import WebSection from '../web-section/WebSection'

export interface CardProps {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    content: any
    type: string
    showImage?: boolean
    leftColSpan?: string
    rightColSpan?: string
    showDate?: boolean
    homepage?: boolean
    teaserClass?: string
    fallBackImg?: string
    dataTrackable?: string
    className?: string
    featured?: boolean
}

const Card: React.FC<CardProps> = ({
    content,
    type: cardType,
    showImage = true,
    leftColSpan,
    rightColSpan,
    showDate = false,
    homepage = false,
    teaserClass,
    fallBackImg,
    dataTrackable,
    className,
    featured = false,
}) => {
    const [placeholderImage, setPlaceholderImage] = useState<string | null>(
        null
    )
    const isPodcast =
        content.tags &&
        content.tags.genre &&
        content.tags.genre.label === 'Podcast'
    const type = cardType || getCardType(content)

    const getCardImage = () => {
        const imageLinkStyle = {
            width: '100%',
        }
        const imageContentContainerClassName = [
            'image-content_container',
            ...(teaserClass === 'stream'
                ? ['image-content_container--stream']
                : []),
            ...(isPodcast ? ['image-content_container--podcast'] : []),
        ].join(' ')

        return (
            <>
                {showImage && (
                    <div
                        className='card__image-content'
                        data-o-grid-colspan={leftColSpan}
                    >
                        <div className={imageContentContainerClassName}>
                            <ImageLink
                                href={content.url}
                                style={imageLinkStyle}
                                imgSrc={getImageSource()}
                                parameters={{
                                    width: 760,
                                    height: 430,
                                    isVideo:
                                        content.image &&
                                        content.image.isVideoTeaser,
                                }}
                                dataTrackable={dataTrackable}
                            />
                        </div>
                    </div>
                )}
            </>
        )
    }

    const getCardContent = () => {
        const cardTeaserClass = teaserClass
            ? 'card__teaser--' + teaserClass
            : ''
        const sponsorContent = getSponsorContent(type as string, content)
        const dateContent = getDateContent(content)
        const partnerName = getPartnerName(type as string, content)
        const badge = getBadgeDate(content)
        const isVideoArticle =
            content.tags &&
            content.tags['genre'] &&
            content.tags['genre'].label === 'Video'
        const colspan = rightColSpan
            ? `${rightColSpan} ${!showImage ? 'M12' : null}`
            : null

        const showTag = !partnerName && !sponsorContent

        return (
            <>
                <div className='card__content' data-o-grid-colspan={colspan}>
                    <div
                        className={`card__content-container card__content-container--${type}`}
                    >
                        <div className='card__content-container-inner'>
                            {cardType === CARD_TYPES.EVENT ? (
                                <div className='card__event-type'>
                                    {content.type &&
                                        content.type.replace('fDi-', '')}
                                </div>
                            ) : (
                                <div className='card__websection-date'>
                                    <WebSection
                                        tags={
                                            content.tags ||
                                            content.specialReportTags
                                        }
                                        type={type || ''}
                                        partner={partnerName}
                                    />
                                    {showDate && !homepage && (
                                        <p className='card__date'>
                                            {dateContent}
                                        </p>
                                    )}
                                </div>
                            )}
                            <div className='card__content-heading'>
                                <div
                                    id={`card__title--${content.id}`}
                                    className={`card__title${cardType === CARD_TYPES.EVENT ? '-event' : ''}`}
                                >
                                    <Headline>
                                        {isPodcast && (
                                            <div className='card__title-podcast-icon' />
                                        )}
                                        {isVideoArticle && (
                                            <div className='card__video-icon'>
                                                <FalsePlayButton />
                                            </div>
                                        )}
                                        {content.title}
                                    </Headline>
                                </div>
                                {content.__typename === 'SpecialReport' ? (
                                    <div
                                        id={`card__special-report--${content.id}`}
                                    >
                                        <Tag
                                            type='special-report'
                                            label='Special Report'
                                        />
                                    </div>
                                ) : (
                                    type === CARD_TYPES.ADVERTORIAL && (
                                        <div
                                            id={`card__advertorial--${content.id}`}
                                        >
                                            {showTag && (
                                                <Tag type={type} label={type} />
                                            )}
                                            {sponsorContent}
                                        </div>
                                    )
                                )}
                            </div>
                            <div
                                id={`card__teaser--${content.id}`}
                                className={`card__teaser ${cardTeaserClass}`}
                            >
                                <Teaser
                                    className='card__teaser-text'
                                    content={
                                        content.teaser || content.description
                                    }
                                    trimLength={100}
                                />
                                {!homepage && type !== 'partner' && (
                                    <div className='card__date'>
                                        {dateContent}
                                        {isVideoArticle && badge}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function getSponsorContent(type: string, article: any) {
            return type === CARD_TYPES.ADVERTORIAL &&
                article.advertorial_sponsor &&
                article.advertorial_sponsor.length > 0 ? (
                <div className='card__sponsor'>
                    Supported by {article.advertorial_sponsor[0].name}
                </div>
            ) : null
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function getPartnerName(type: string, article: any) {
            return type === 'partner' &&
                article.partner_content &&
                article.partner_content.length > 0
                ? article.partner_content[0].name
                : null
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function getBadgeDate(article: any) {
            if (!article.publish_date && !article.publication_date) return null
            const dateToUse = article.publish_date || article.publication_date

            try {
                const publishDate = new Date(dateToUse)
                if (!publishDate) return null
                const currentDate = new Date()
                const monthsDiff =
                    (currentDate.getFullYear() - publishDate.getFullYear()) *
                        12 +
                    (currentDate.getMonth() - publishDate.getMonth())
                let badgeClass = 'card__badge-date'
                let badgeText = ''
                if (monthsDiff <= 1) {
                    badgeClass += ' card__badge-date--hot'
                    badgeText = 'Hot'
                } else if (monthsDiff <= 3) {
                    badgeClass += ' card__badge-date--warm'
                    badgeText = 'Warm'
                } else {
                    return null
                }

                return (
                    <>
                        <div className={badgeClass}>
                            <span>{badgeText}</span>
                        </div>
                    </>
                )
            } catch (e) {
                console.log(e)
            }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function getDateContent(article: any) {
            if (type === CARD_TYPES.EVENT) {
                return (
                    <div className='o-grid-row'>
                        <div data-o-grid-colspan='12'>
                            Event:{' '}
                            {article.start_date === article.end_date ? (
                                <DateComponent
                                    dateTime={article.start_date}
                                    format={'d MMMM yyyy'}
                                />
                            ) : (
                                <>
                                    <DateComponent
                                        dateTime={article.start_date}
                                        format={'d MMMM'}
                                    />{' '}
                                    -{' '}
                                    <DateComponent
                                        dateTime={article.end_date}
                                        format={'d MMMM yyyy'}
                                    />
                                </>
                            )}
                        </div>
                        <div data-o-grid-colspan='12'>{article.location}</div>
                    </div>
                )
            }

            if (
                article.ranking &&
                article.ranking.closing_date &&
                article.ranking.publish_date
            ) {
                return (
                    <>
                        Closing date for submissions:{' '}
                        <DateComponent
                            dateTime={article.ranking.closing_date}
                        />
                        <br />
                        Results published:{' '}
                        <DateComponent
                            dateTime={article.ranking.publish_date}
                        />
                    </>
                )
            }

            if (article.publication_date || article.publish_date) {
                return (
                    <DateComponent
                        dateTime={
                            content.publication_date || content.publish_date
                        }
                    />
                )
            }
        }
    }

    const getImageSource = () => {
        if (content.image && content.image.image) {
            return content.image.image
        } else if (fallBackImg) {
            return fallBackImg
        }

        if (!placeholderImage) {
            const image = getRandomPlaceholderImage()
            setPlaceholderImage(image)
            return image
        }

        return placeholderImage
    }

    return (
        <div
            className={`card card--${type} ${className ? 'card--' + className : ''} ${!showImage ? 'card--article-no-image' : ''}`}
        >
            <div className='card__inner'>
                {featured ? <div className='card__featured'>Featured</div> : ''}
                <a
                    href={content.url}
                    className='card--article-link'
                    data-trackable={dataTrackable}
                    aria-labelledby={`card__title--${content.id} card__advertorial--${content.id} card__special-report--${content.id} card__teaser--${content.id}`}
                />
                {getCardImage()}
                {getCardContent()}
            </div>
        </div>
    )
}

Card.defaultProps = {
    showDate: false,
    showImage: true,
}

export default Card
