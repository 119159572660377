// const articleProps = require('@financial-times/fdi-frontend-components/article/default')

import { EZ_OBJECT_TYPES, CARD_TYPES } from '../../assets/constants'

/**
 * Shuffles array in place.
 * @param {Array} array An array containing the items.
 */
export function shuffle<T>(array: T[]): T[] {
    let j: number, x: T, i: number

    for (i = array.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1))
        x = array[i]
        array[i] = array[j]
        array[j] = x
    }

    return array
}

const specialTags: Record<string, string> = {
    Opinion: '/opinion',
    Podcast: '/fdi-podcast',
    News: '/stream/News',
    Locations: '/global',
    'Rankings & Awards': '/rankings-and-awards',
    'Special Report': '/reports-and-whitepapers',
    'Free Zone Focus': '/opinion/free-zone-focus',
    'The Global Lawyer': '/opinion/the-global-lawyer',
    'Inside fDi': '/opinion/inside-fdi',
    'View from Middle East & Africa': '/opinion/view-from/middle-east-africa',
    'View from Europe': '/opinion/view-from/europe',
    'View from Asia-Pacific': '/opinion/view-from/asia-pacific',
    'View from Americas': '/opinion/view-from/americas',
}

/**
 * Returns a URL for the tag from a given tag object.
 *
 * @param {Object} tag A tag object
 * @param {Object} locationTags All tags that were selected
 * @return {String}
 */
interface Tag {
    label: string
    path: string
}

export function getStreamUrlFromTag(
    tag: Tag | null | undefined
): string | null {
    if (!tag || !tag.label || !tag.path) {
        return null
    }

    const base = '/stream'

    if (tag.label in specialTags) {
        return encodeURI(specialTags[tag.label])
    }

    if (tag.label === 'Advertorial') {
        return encodeURI(`${base}/partner-content`)
    }

    const cleanPath = tag.path.replace(/\s+/g, '-')
    if (/^Global\/?/.test(cleanPath)) {
        return encodeURI(
            '/' +
                cleanPath.substring(0, 1).toLowerCase() +
                cleanPath.substring(1)
        )
    }

    if (/^(Award\/?|Ranking\/?)/.test(cleanPath)) {
        return encodeURI(`${base}/${tag.label}`)
    }

    return encodeURI(`${base}/${cleanPath}`)
}

/**
 * Returns an array of fake articles.
 *
 * @param {number} n Number of articles to populate the array with.
 *
 * @returns {object[]} An array of fake articles.
 */
// module.exports.getFakeArticles = (n = 10) => {
//     return Array.from(Array(n), (_, k) => ({ ...articleProps.article, id: k }))
// }

/**
 * Used to prevent warnings where for some reason URL param would get value of '{param} line 229 > scriptElement'.
 * The function also lower-cases the string.
 *
 * @param {string} param URL parameter
 *
 * @returns {string} First word lower-case value of a given param.
 */
export function cleanParam(param: string): string | null {
    if (!param) {
        return null
    }

    const endIndex = param.indexOf(' line ')
    const actualParam = endIndex === -1 ? param : param.slice(0, endIndex)

    return actualParam.toLowerCase()
}

export interface Content {
    __typename?: string
    description?: string
    specialReportTags?: unknown
    tags?: unknown
    publish_date?: string
    type?: string
    eventTeaser?: string
    teaser?: string
    eventTags?: unknown
    [key: string]: unknown
}

export function getCardContent(content: Content | null): Content | null {
    if (!content || !content.__typename) {
        return null
    }

    switch (content.__typename) {
        case EZ_OBJECT_TYPES.SPECIAL_REPORT: {
            return {
                ...content,
                teaser: content.description,
                tags: content.specialReportTags || content.tags,
                publication_date: content.publish_date,
            }
        }
        case EZ_OBJECT_TYPES.EVENT_HUB: {
            return {
                ...content,
                eventType: content.type,
                teaser: content.eventTeaser || content.teaser,
                tags: content.eventTags || content.tags,
            }
        }
        default:
            return content
    }
}

export function getCardType(content: Content | null): string | null {
    if (!content || !content.__typename) {
        return null
    }

    if (content.__typename === EZ_OBJECT_TYPES.EVENT_HUB) {
        return CARD_TYPES.EVENT
    }

    if (
        content.partner_content &&
        Array.isArray(content.partner_content) &&
        content.partner_content.length > 0
    ) {
        return CARD_TYPES.PARTNER
    }

    return content.advertorial_sponsor &&
        Array.isArray(content.advertorial_sponsor) &&
        content.advertorial_sponsor.length
        ? CARD_TYPES.ADVERTORIAL
        : CARD_TYPES.ARTICLE
}

/**
 * Return string without html tags inside
 *
 * @param {String} text
 *
 * @returns {String}
 */
export function cleanHtmlTags(text: string): string {
    return text.replace(/<\/?[^>]+(>|$)/g, '') // Remove html tags inside.
}

export function matchAllIndex(
    regex: RegExp,
    string: string,
    maxPosition: number
): number[] {
    let index = 0
    let match: RegExpExecArray | null
    const paragraphsIndexes: number[] = []
    while ((match = regex.exec(string)) !== null && index < maxPosition) {
        paragraphsIndexes.push(match.index)
        index += 1
    }
    return paragraphsIndexes
}

export function slugify(input: string): string {
    return input
        .toLowerCase()
        .trim()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-')
}

export function getContentSlug(article: {
    __typename: string
    title: string
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tags?: any
    url?: string
}): string {
    if (article.__typename === 'EventHub') {
        return `/event-hub/${slugify(article.title)}-${article.id}`
    }

    if (article.__typename === 'SpecialReport') {
        return `/special-report/${slugify(article.title)}-${article.id}`
    }

    // @TODO: turn article URLs into `/article/title-slug-${id}`
    const section = article.tags?.genre?.path || 'News'
    return `/content/${slugify(section)}/${slugify(article.title)}-${article.id}`
}

export function setContentSlug(article: {
    __typename: string
    title: string
    id: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tags?: any
    url?: string
}): void {
    article.url = getContentSlug(article)
}

export function formattedString(template: string, ...args: string[]): string {
    return template.replace(
        /{(\d+)}/g,
        (match: string, number: string): string => {
            const index = parseInt(number, 10)
            return typeof args[index] !== 'undefined' ? args[index] : match
        }
    )
}

export function formattedStringWithLinks(
    template: string,
    ...args: string[]
): { __html: string } {
    const isValid = args.every(arg => /^[\w\s,.;!?|:/?#&%-]+$/.test(arg))

    if (!isValid) {
        throw new Error('Invalid characters detected in arguments.')
    }

    const formattedString = template.replace(
        /{(\d+)}/g,
        (match: string, number: string): string => {
            const index = parseInt(number, 10)
            const data = args[index] ? args[index].split('|') : null
            const text = data && data[0] ? data[0] : match
            const link = data && data[1] ? data[1] : '#'
            return `<a target="_blank" style="font-weight: bold" href="${link}">${text}</a>`
        }
    )

    return { __html: formattedString }
}

const products = [
    'fDi Influencers',
    'fDi Markets',
    'fDi Benchmark',
    'fDi Intelligence',
    'fDi Strategy',
    'Financial Times',
]

/**
 * Function that checks if is mobile device and returns true if it is
 * @returns {boolean} - True if is mobile device
 */
const isMobile = () => {
    if (typeof window === 'undefined') return false
    return window.innerWidth < 768
}

/**
 * Function to replace product names with non-breaking space versions and prevent wrapping of the last two or three words
 * @param {string} text - The text to process
 * @returns {string} - The processed text
 */
export const __w = (text: string, dontBreak = false): string => {
    if (isMobile() && dontBreak) return text

    const replaceProductNames = (text: string): string => {
        products.forEach(product => {
            const nonBreakingProduct = product.replace(' ', '\u00A0')
            text = text.replace(new RegExp(product, 'g'), nonBreakingProduct)
        })
        return text
    }

    let processedText = replaceProductNames(text)

    const words = processedText.split(' ')
    if (words.length > 1) {
        const lastWord = words[words.length - 1]
        const secondLastWord = words[words.length - 2]

        if (
            secondLastWord.length <= 3 &&
            lastWord.length <= 3 &&
            words.length > 2
        ) {
            const thirdLastWord = words[words.length - 3]
            if (thirdLastWord.length <= 3) {
                words[words.length - 3] +=
                    `\u00A0${words[words.length - 2]}\u00A0${words[words.length - 1]}`
                words.splice(words.length - 2, 2)
            } else {
                words[words.length - 2] += `\u00A0${words[words.length - 1]}`
                words.pop()
            }
        } else {
            words[words.length - 2] += `\u00A0${words[words.length - 1]}`
            words.pop()
        }
    }

    processedText = words.join(' ')

    return processedText
}

export const generateLinkAttributes = (
    link: string,
    basePath = '/products-and-services'
): {
    href: string
    target?: string
    rel?: string
} => {
    if (link.startsWith('http')) {
        return {
            href: link,
            target: '_blank',
            rel: 'noreferrer',
        }
    }
    if (link.startsWith('/')) {
        return {
            href: link,
        }
    }
    return {
        href: `${basePath}/${link}`,
    }
}

interface Product {
    title: string
    talkToOurExperts?: string
    contactUs?: string
    externalLink?: string
    link?: string
}

/**
 * Function to get the similar product links for a product based on the product object
 * @param {object} product - The product object
 * @returns {object} - The similar product links object
 */
export const transformLinks = (product: Product) => {
    let btnText = product.talkToOurExperts
        ? 'Talk to our experts'
        : 'Request a demo'
    btnText =
        product.title === 'Events and Roundtables' ? 'Enquire now' : btnText
    btnText = product.contactUs ? 'Contact us' : btnText
    const titleSlug =
        product.title === 'ZoomProspector' || product.title === 'ZoomTour'
            ? 'GIS%20Planning'
            : product.title
    let btnLink = product.talkToOurExperts
        ? product.talkToOurExperts
        : `/form/contact-us/${titleSlug}`
    btnLink = product.contactUs ? product.contactUs : btnLink
    let trackable = product.talkToOurExperts
        ? 'talk-to-our-experts-cta'
        : 'request-a-demo-cta'
    trackable =
        product.title === 'Events and Roundtables'
            ? 'enquire-now-cta'
            : trackable
    trackable = product.contactUs ? 'contact-us-cta' : trackable

    const link =
        product && product.externalLink ? product.externalLink : product.link

    return {
        btnText,
        btnLink,
        trackable,
        link,
    }
}
