import { Article, ArticleTags, SCAPIAnnotations, SCAPIArticle } from '../types'
import typesConfig from '../assets/articleTypesConfig'
import colours from '../utils/colours'
import {
    EXECLUDED_BRANDS_LIST,
    POWERED_BY_BRANDS_LIST,
    POWERED_BY_OPTIONS_REVERSE,
} from '../assets/constants'

export const GENRE_ARTICLE_TYPES = {
    Podcast: 'Podcast',
    Video: 'video',
    'Rankings and awards': 'rankings',
    Advertorial: 'advertorial',
} as const

export const BRAND_ARTICLE_TYPES = {
    Advertorial: 'advertorial',
    'Inside fDi': 'inside',
    'The Global Lawyer': 'globalLawyer',
    'Free Zone Focus': 'freezone',
    'View from Americas': 'americas',
    'View from Europe': 'europe',
    'View from Asia-Pacific': 'asia',
    'View from Middle East & Africa': 'middleEast',
} as const

export function getGenreAndBrand(article: Article) {
    const genre =
        article &&
        article.tags &&
        article.tags.genre &&
        article.tags.genre.label
            ? article.tags.genre.label
            : ''
    const brand =
        article &&
        article.tags &&
        article.tags.brand &&
        article.tags.brand.label
            ? article.tags.brand.label
            : ''

    return [genre, brand]
}

export function getArticleType(genre: string, brand: string) {
    let type = (GENRE_ARTICLE_TYPES[
        genre as keyof typeof GENRE_ARTICLE_TYPES
    ] ||
        BRAND_ARTICLE_TYPES[
            brand as keyof typeof BRAND_ARTICLE_TYPES
        ]) as string

    if (genre === 'Opinion' && type == null) {
        type = 'generalOpinion'
    }

    return type || ''
}

export function getColour(articleType: string) {
    const colourString = typesConfig[articleType as keyof typeof typesConfig]
        ? typesConfig[articleType as keyof typeof typesConfig].colour
        : null
    const colour = colours.find(colour => {
        return colour.name === colourString
    })

    return colour ? colour.hex : null
}

export function parseArticleTags(articleTags: ArticleTags) {
    const tags = Object.entries(articleTags).reduce(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (acc: [string, any][], [, value]) => {
            if (!value) {
                return acc
            }

            if (Array.isArray(value)) {
                const tags = value.map(tag => tag.label)
                return [...tags, ...acc]
            }

            if (!value.label) {
                return acc
            }

            return [value.label, ...acc]
        },
        []
    )

    return tags
}

export const mapTagsFromAnnotations = (annotations?: SCAPIAnnotations) => {
    const genre = annotations?.genres[0] || null
    const brand =
        annotations?.brands.filter(
            brand =>
                !EXECLUDED_BRANDS_LIST.find(option => option.id === brand.id)
        )[0] || null

    const nominated = annotations?.displayTags[0] || null

    return {
        genre: genre
            ? {
                  id: genre.id,
                  label: genre.name,
                  name: genre.name,
                  slug: genre.slug || '',
                  path: genre.name,
              }
            : null,
        brand: brand
            ? {
                  id: brand.id,
                  label: brand.name,
                  name: brand.name,
                  slug: brand.slug || '',
                  path: brand.name,
              }
            : null,
        nominated: nominated
            ? {
                  id: nominated.id,
                  label: nominated.name,
                  name: nominated.name,
                  slug: nominated.slug || '',
                  path: nominated.name,
              }
            : null,
        ...annotations?.abouts.reduce((acc, tag) => {
            if (!tag?.name) return acc

            return {
                ...acc,
                [tag.name]: {
                    id: tag.id,
                    label: tag.name,
                    name: tag.name,
                    slug: tag.slug || '',
                },
            }
        }, {}),
    }
}

const mapPoweredByFromBrands = (brands: SCAPIAnnotations['brands']) => {
    return brands.find(brand =>
        POWERED_BY_BRANDS_LIST.find(option => option.id === brand.id)
    )
}

export const mapSCAPItoFDIArticle = (article: SCAPIArticle) => {
    const poweredBy = mapPoweredByFromBrands(article?.annotations?.brands || [])
    return {
        // Basic fields
        id: article.id,
        title: article.title,
        publication_date: article.publicationDate,
        unpublish_date: null,
        body_text: article.body,
        teaser: article.lede || '',

        // Video handling
        videoid: article.video?.videoId || null,

        // Source and type
        powered_by: poweredBy
            ? POWERED_BY_OPTIONS_REVERSE[
                  poweredBy.name as keyof typeof POWERED_BY_OPTIONS_REVERSE
              ]
            : 'NONE',

        // Image handling - maintaining old structure
        image: article.teaserImage
            ? {
                  id: '', // Would need mapping if available
                  name: article.title,
                  altTag: article.title,
                  caption: article.captionImage || '',
                  image: article.teaserImage,
                  isVideoTeaser: article.video ? true : false,
                  remoteId: null,
                  cacheId: null,
                  tags: null,
              }
            : undefined,

        // Tags handling - converting new tag structure to old
        tags: mapTagsFromAnnotations(article?.annotations),

        // TODO: map the author
        author: article.authors?.map(author => ({
            id: author.id,
            name: author.name,
            image: author.image.url,
        })),

        // SEO fields
        seo_page_title: article.seoMetaTitle || '',
        seo_meta_keywords: article.seoMetaKeywords || '',
        seo_meta_description: article.seoMetaDescription || null,

        // Additional fields
        hide_header_image: false,
        teaser_image_caption: article.captionImage || '',
        advertorial_sponsor_disclaimer: '',
        acast_podcast_id: article.podcast?.id || '',
        cacheId: article.id,
        url: article.url || `/content/${article.id}`,
    }
}
